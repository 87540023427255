// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-people-js": () => import("./../../../src/pages/our-people.js" /* webpackChunkName: "component---src-pages-our-people-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-equipment-category-js": () => import("./../../../src/templates/equipment-category.js" /* webpackChunkName: "component---src-templates-equipment-category-js" */),
  "component---src-templates-equipment-single-js": () => import("./../../../src/templates/equipment-single.js" /* webpackChunkName: "component---src-templates-equipment-single-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-press-release-single-js": () => import("./../../../src/templates/press-release-single.js" /* webpackChunkName: "component---src-templates-press-release-single-js" */),
  "component---src-templates-project-category-js": () => import("./../../../src/templates/project-category.js" /* webpackChunkName: "component---src-templates-project-category-js" */),
  "component---src-templates-project-single-js": () => import("./../../../src/templates/project-single.js" /* webpackChunkName: "component---src-templates-project-single-js" */),
  "component---src-templates-team-single-js": () => import("./../../../src/templates/team-single.js" /* webpackChunkName: "component---src-templates-team-single-js" */)
}

