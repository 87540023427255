import React, { createContext, useState } from 'react';

const defaultValues = {
  isCallbackOpen: false,
  toggleCallbackOpen: () => {},
};

export const SiteContext = createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isCallbackOpen, setCallbackOpen] = useState(false);

  const toggleCallbackOpen = () => setCallbackOpen(!isCallbackOpen);

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        isCallbackOpen,
        toggleCallbackOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
